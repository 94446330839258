import { useEffect, useState } from "react";
import { checkBrowser } from "./checkBrowser";

const ClientInfo = () => {
  const [info, setInfo] = useState({
    geo: "",
    history: 0,
  });

  useEffect(() => {
      console.log("webdriver",navigator.webdriver);
    // // user agent
    setInfo((prev) => {
      return { ...prev, userAgent: navigator.userAgent };
    });

    // // location
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        const lati = position.coords.latitude;
        const longi = position.coords.longitude;
        setInfo((prev) => {
          return { ...prev, geo: `${lati}  ${longi}` };
        });
      },
      function (error) {
        setInfo({ ...info, geo: "not available" });
        alert(error.message);
      }
    );
    // // previous site
    setInfo((prev) => {
      return { ...prev, history: window.history.length };
    });
    // // vendor
    setInfo((prev) => {
      return { ...prev, vendor: navigator.vendor };
    });
    // // ip;
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((json) => {
        console.log(json.ip);
        setInfo((prev) => {
          return { ...prev, ip: json.ip };
        });
      })
      .catch((error) => alert(error));
    // browser
    setInfo((prev) => {
      //   return { ...prev, os: navigator.userAgentData.platform };
      return { ...prev, browser: checkBrowser() };
    });

    // // language
    setInfo((prev) => {
      //   return { ...prev, os: navigator.userAgentData.platform };
      return { ...prev, language: navigator.language };
    });

    // // platform
    setInfo((prev) => {
      return { ...prev, platform: navigator.platform };
    }); 

    // os
    setInfo((prev) => {
        return { ...prev, os: navigator.userAgentData.platform };
      // return { ...prev, os: "N/A" };
    });
    // mobile
    setInfo((prev) => {
      return { ...prev, mobile: navigator.userAgentData.mobile };
      // return { ...prev, mobile: "N/A" };
    });
    // // bot
    setInfo((prev) => {
      return { ...prev, bot: navigator.webdriver };
    });

    // // navigotor plugins
    setInfo((prev) => {
      return { ...prev, plugins: navigator.plugins };
    });
    // // navigator mimeTypes
    setInfo((prev) => {
      return { ...prev, mimeTypes: navigator.mimeTypes };
    });
    // // bluetooth
    const btObj = navigator.bluetooth
    // get devices if bluetooth is supported
    if (btObj) {
      // btObj.getDevices().then(devices => {
      //   setInfo((prev) => {
      //     return { ...prev, bluetooth: devices };
      //   });
      // });
      btObj.getAvailability().then(availability => {
        setInfo((prev) => {
          return { ...prev, bluetooth: availability};
        });
      });
    } else {
      setInfo((prev) => {
        return { ...prev, bluetooth: "N/A" };
      }
      );
    }
    // // clipboard
    const clipboard = navigator.clipboard 
    if (clipboard) {
      setInfo((prev) => {
        return { ...prev, clipboard: "YES" };
      });
    } else {
      setInfo((prev) => {
        return { ...prev, clipboard: "N/A" };
      });
    }
    // // connection 
    const connection = navigator.connection
    if (connection) {
      setInfo((prev) => {
        return { ...prev, connection: connection.effectiveType };
      }
      );
    } else {
      setInfo((prev) => {
        return { ...prev, connection: "N/A" };
      });
    }
    // // contact
    const supported = "contacts" in navigator && "ContactsManager" in window;
    if (supported) {
      setInfo((prev) => {
        return { ...prev, contact: "Contacts API supported" };
      });
    } else {
      setInfo((prev) => {
        return { ...prev, contact: "N/A" };
      });
    }

    // // cookie enabled
    setInfo((prev) => {
      return { ...prev, cookieEnabled: navigator.cookieEnabled
      };
    });
    // // credentials
    const credentials = navigator.credentials
    if (credentials) {
      setInfo((prev) => {
        return { ...prev, credentials: "YES" };
      });
    } else {
      setInfo((prev) => {
        return { ...prev, credentials: "N/A" };
      });
    }
    // // device memory
    setInfo((prev) => {
      return { ...prev, deviceMemory: navigator.deviceMemory };
    });
    // // gpu info
    const gpuInfo = navigator.gpu
    if (gpuInfo) {
      setInfo((prev) => {return { ...prev, gpu: "Web GPU supported" }});
     } else {
      setInfo((prev) => { return { ...prev, gpu: "N/A" }});
    }
    // // hardware conccurent
    setInfo((prev) => {
      return { ...prev, hardwareConcurrency: navigator.hardwareConcurrency };
    }
    );
    // // hid
    const hid = navigator.hid
    // get devices if hid is supported
    if (hid) {
      hid.getDevices().then(devices => {
        setInfo((prev) => {
          return { ...prev, hid: devices };
        });
      });
    } else {
      setInfo((prev) => {
        return { ...prev, hid: "N/A" };
      }
      );
    }
    
    // // keyboard
    const keyboard = navigator.keyboard
    // get layout if keyboard is supported
    if (keyboard) {
      keyboard.getLayoutMap().then(layout => {
        const wKeys = layout.get("KeyW");
        setInfo((prev) => {
          return { ...prev, keyboard: wKeys };
        }
        );
      });
    } else {
      setInfo((prev) => {
        return { ...prev, keyboard: "N/A" };
      });
    }

    // // media devices
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setInfo((prev) => {
        return { ...prev, mediaDevices: devices };
      });
    });


    // // // WINDOW OBJECT
    // // window chrome
    setInfo((prev) => {
      return { ...prev, chrome: window.chrome };
    });


  }, []);
  return (
    <>
      <div>Client Info</div>
      <table className="table">
        <tbody>
          <tr>
            <td>User Agent</td>
            <td>{info.userAgent}</td>
          </tr>
          <tr>
            <td>Geo</td>
            <td>{info.geo}</td>
          </tr>
          <tr>
            <td>Read pages</td>
            <td>{info.history}</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>{info.vendor}</td>
          </tr>
          <tr>
            <td>IP</td>
            <td>{info.ip}</td>
          </tr>
          <tr>
            <td>Language</td>
            <td>{info.language}</td>
          </tr>
          <tr>
            <td>Platform</td>
            <td>{info.platform}</td>
          </tr>
          <tr>
            <td>Browser</td>
            <td>{info.browser}</td>
          </tr>
           <tr>
            <td>OS</td>
            <td>{info.os}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>{info.mobile ? "YES" : "NO"}</td>
          </tr> 
          <tr>
            <td>Automated</td>
            <td>{info.bot ? "YES" : "NO"}</td>
          </tr>
          <tr>
            <td>Plugins old</td>
            <td>{JSON.stringify(info.plugins)}</td>
          </tr>
          <tr>
            <td>MimeTypes old</td>
            <td>{JSON.stringify(info.mimeTypes)}</td>  
          </tr>
          <tr>
            <td>Bluetooth (S)</td>
            {/* <td>{info.bluetooth ? "YES" : "NO"}</td>   */}
            <td>{info.bluetooth }</td>  
          </tr>
          <tr>
            <td>Clipboard</td>
            <td>{info.clipboard}</td>
          </tr>
          <tr>
            <td>Connection</td>
            <td>{info.connection}</td>
          </tr>
          <tr>
            <td>Contact</td>
            <td>{info.contact}</td>
          </tr>
          <tr>
            <td>Cookie Enabled</td>
            <td>{info.cookieEnabled ? "YES" : "NO"}</td>
          </tr>
          <tr>
            <td>Hardware Concurrency</td>
            <td>{info.hardwareConcurrency}</td>
          </tr>
          <tr>
            <td>Credentials (S)</td>
            <td>{info.credentials}</td>
          </tr>
          <tr>
            <td>GPU (S)</td>
            <td>{info.gpu}</td>
          </tr>
          <tr>
            <td>Device Memory (S)</td>
            <td>{info.deviceMemory}</td>
          </tr>
          <tr>
            <td>HID (only Edge) (S)</td>
            <td>{JSON.stringify(info.hid)}</td>
          </tr>
          <tr>
            <td>Keyboard W key (S)</td>
            <td>{JSON.stringify(info.keyboard)}</td>
          </tr>
          <tr>
            <td>Media Devices</td>
            <td>{JSON.stringify(info.mediaDevices)}</td>
          </tr>

          {/* WINDOW OBJ */}
          <tr>
            <td>Chrome</td>
            <td>{JSON.stringify(info.chrome)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ClientInfo;
